export default 
[
    // {
    //     name: 'Helping Your Family',
    //     conditionUrl: 'thank-you-aboo',
    //     intro: 'Dear Aboo,',
    //     anatomy: 'For so long you have watched over us, helping us grow beyond our weaknesses and teaching us how to pursue our goals.',
    //     causes: 'As your son, I am grateful for your help and guidance as to asserting myself and to keep us safe. I will continue working on myself to build my own foundation, and find my own path. Sorry for the late message, but I wanted to make a creative wish for you. I hope this message serves well for you. Happy Fathers Day.',
    //     symptoms: 'Love, Mekaal',
    //     photo: require('./Staff/father-day.JPG')
    // },
    {
        name: 'Coronary Artery Disease',
        offName: 'CORONARY ARTERY DISEASE',
        conditionUrl: 'coronary-artery-disease',
        info: 'Disease where a blockage of the coronary arteries puts patients at risk for heart attack and other fatal diseases.',
        intro: 'Coronary artery disease puts patients at risk for heart attacks and other heart diseases. Coronary arteries are located on the heart, and supply blood to the muscle to keep it functional. Blockages of those arteries severely limit heart function.',
        causes: 'The arteries that lead into the heart are blocked by plaque which causes them to narrow. This narrowing limits blood flow, and plaque can even block the passage entirely, leading to ruptured arteries and other major damage to the heart.',
        symptoms: 'Coronary artery disease is caused by chest pain, chest pressure, chest discomfort, irregular heart beat, and shortness of breath are common symptoms of heart attacks. Patients can also experience pain or discomfort in the upper body areas such as the arms, neck, jaw, stomach, and back. Other indicators are nausea, vomiting, burping, or heartburn. Patients at risk of a heart attack can feel dizzy or lightheaded as well as cold or sweaty during a heart attack. Do not take yourself to the hospital or any other medical provider. Patients with carotid artery disease can have chest pain while walking, traveling up stairs, or participating in an activity even if they are not suffering from a heart attack.',
        diagnosis: 'To determine if you are at risk, an electrocardiogram (ECG) to measure the electrical activity of the heart or an echocardiogram to view an ultrasound may be used for testing. The doctor may recommend a stress test. You will exercise on a treadmill while having an ECG attached, analyzing if the heart is getting enough blood when under stress. A cardiac catheterization may be employed which involves the doctor injecting a tube into one of your vessels at the leg or arm that travels to the heart. Then, a coronary angiography is performed by injecting dye into the tube, indicating any arteries that lead to the heart that are clogged.',
        treatment: 'The ideal way to reduce risk for coronary artery disease is to make positive changes for your body. Quit smoking, start exercising to gain muscle and lose excess weight, and adopt a low fat and cholesterol diet by eating more fruits, vegetables, and low-fat dairy foods. It is also advised to take medications for lowering blood pressure, lowering cholesterol (statins), treating diabetes, and preventing blood clots (aspirin). Medications such as nitrates and beta blockers can also be used to relieve angina, or chest pain caused by coronary artery disease. If you are at high risk, then a stent procedure may be suggested. The stent is inserted into the coronary artery to open it more. Surgery to remove the plaque, carotid endarterectomy, may be suggested if patients suffered from a TIA or stroke caused by plaque buildup. A coronary artery bypass surgery may be needed. It involves the creation of a new path around the blood clot using grafts of your other arteries. This surgery allows the blood to take a detour around the blocked area. Patients will need to stay at the hospital for 3 to 5 days to allow their condition to stabilize.'
    },
    {
        name: 'Myocardial Infarction',
        offName: 'MYOCARDIAL INFARCTION',
        conditionUrl: 'myocardial-infarction',
        info: 'Most commonly known as the heart attack that results from a blockage within one of the coronary arteries.',
        intro: 'Most commonly known as a heart attack, myocardial infarction occurs when one of the coronary arteries (arteries connected to the heart) is blocked. As a result, the section of the heart that receives blood from a coronary artery is damaged. A longer period of a blocked artery means a more severe heart attack.',
        causes: 'Myocardial infarction commonly results from coronary artery disease, where plaque buildup within the arteries interferes with blood flow, potentially forming blood clots. They can block the artery, keeping blood away from the heart.',
        symptoms: 'Chest pain, chest pressure, chest discomfort, irregular heart beat, and shortness of breath are common symptoms of heart attacks. Patients can also experience pain or discomfort in the upper body areas such as the arms, neck, jaw, stomach, and back. Other indicators are nausea, vomiting, burping, or heartburn. Patients can feel dizzy or lightheaded as well as cold or sweaty during a heart attack. Do not take yourself to the hospital or any other medical provider.',
        diagnosis: 'To determine if you are at risk, the doctor may recommend a blood test to find chemicals that indicate if you are suffering a heart attack, an electrocardiogram (ECG) to measure the electrical activity of the heart, or an echocardiogram to view an ultrasound. A cardiac catheterization may be employed which involves the doctor injecting a tube into one of your vessels at the leg or arm that travels to the heart. Then, a coronary angiography is performed by injecting dye into the tube, indicating any arteries that lead to the heart that are clogged.',
        treatment: 'Heart attacks treated at the hospital involve the medical staff to provide oxygen through a mask or tube in your nose. Pain medicaitons to reduce chest pain and discomfort, blood clot prevention medications, or beta blockers that reduce the required oxygen needed may be recommended. They may conduct one of two methods to regain blood flow. The first is "clot buster" injection, which enters the clogged artery through an IV to dissolve blood clots. The second is a cardiac catheterization stenting procedure, where the doctor inserts a stent (a tiny, metal meshed tube) into the targeted artery to keep it open. If those methods do not resolve the attack, then open heart surgery (coronary artery bypass surgery) is suggested. A bypass involves the creation of a new path around the blood clot using grafts of your other arteries. This surgery allows the blood to take a detour around the blocked area. Patients will need to stay at the hospital for 3 to 5 days to allow their condition to stabilize..',
    },
    {
        name: 'Heart Failure',
        offName: 'HEART FAILURE',
        conditionUrl: 'heart-failure',
        info: 'When the heart fails to pump blood at the normal rate, and blood cannot reach areas within the body.',
        intro: 'Heart failure occurs when the heart fails to pump blood well, and cannot supply blood throughout the body. The heart still functions, but not as well as it should.',
        causes: 'Heart failure can be caused by severe conditions experienced in the past, such as abnormal heart rate or even heart attacks.',
        symptoms: 'Patients with heart failure experience fatigue or dizziness, difficulty breathing, high heart rate (even while resting), swollen feet, ankles, legs, or even belly.',
        diagnosis: 'There are many tests that diagnose heart failure. An electrocardiogram (ECG) analyzes the electrical activity of a heart, indicating any abnormal heart activity. Chest X-rays can show any fluid in the lungs, and can display the shape of the heart and its vessels. Echocardiograms generate images of the heart, the size of its chambers, and its performance. A stress test can also help with diagnosing for heart failure by measuring heart performance with exercise, allowing doctors to determine if your heart is pumping enough blood to the rest of the body while exercising. Cardiac catheterization is another option, where a tube is injected into your vessel and travels to the heart. The tube will track heart performance, and a dye is injected to indicate any narrowed or blocked arteries.',
        treatment: 'Follow up on taking your medications. They can improve your condition if you take them as prescribed, and can even prevent you from visiting the hospital to treat severe conditions. Make lifestyle changes such as eating healthier (cutting salt out of your diet, limiting alcohol), exercising to lose weight, and quitting smoking. Devices that stimulate heart pumping are also beneficial for treating heart failure. Surgeries that improve blood flow by expanding arteries with plaque or creating bypass arteries can treat heart failure.',
    },
    {
        name: 'Valvular Heart Disease',
        offName: 'VALVULAR HEART DISEASE',
        conditionUrl: 'valvular-heart-disease',
        info: 'When the heart valves that regulate blood flow fail to open and close properly, leading to possible heart failure.',
        intro: 'Valvular heart disease, commonly known as heart valve disease, occurs when the valves that regulate the direction of blood flow in the heart fail to open and close properly. This may lead the heart to work harder and enlarge, and eventually lead to possible heart failure and even death.',
        causes: 'Valvular heart disease may affect all four of the heart’s valves. Normally, the heart valves control blood flow direction through the heart by opening and closing. Normal oening and closing of the valves creates a normal, healthy heartbeat. With heart valve disease, the valves fail to properly open and close, and may leak or cause blood to flow backward into the heart chambers. This causes the heart to try to compensate by working harder to pump blood out of the heart. This can cause a heart murmur and result in an enlarged heart or heart failure over time.',
        symptoms: 'You may experience indigestion, loss of appetite, nausea, and vomiting. You may have shortness of breath after activities or even just lying down. Your heartbeat may become irregular. Your ankles, legs, abdomen, and arms may become swollen and feel cool. You may experience fatigue, faintness, and weakness. You may sense heart palpitations or a rapid, irregular pulse. You may produce smaller-than-usual amounts of urine and need to urinate more often at night. You may have difficulty remaining alert, concentrating, remembering, and sleeping. You may sweat profusely and cough, particularly at nighttime.',
        diagnosis: 'Using a stethoscope, your doctor will listen to your heart to check if there is a heart murmur. Your doctor may conduct several tests to diagnose valvular heart disease, such as a chest X-ray, electrocardiogram, echocardiogram, or cardiac catheterization. These tests allow your doctor to view detailed images of your heart and evaluate your heart arteries, muscles, and valves.',
        treatment: 'Treatment will be based on the severity and extent of your heart valve disease. Sometimes, careful monitoring will eliminate the need for treatment. While medications cannot cure valvular heart disease, they may be used to improve heart functioning. In some cases, narrowed or leaking heart valves will need to be treated with heart valve surgery. Heart valve surgery is a highly successful open-heart surgery. It is performed under general anesthesia. A surgeon will repair or replace your heart valves with artificial or donor replacement valves. Heart valve surgery will relieve your symptoms and help prolong your life.',
    },
    {
        name: 'Mitral Valve Prolapse',
        offName: 'MITRAL VALVE PROLAPSE',
        conditionUrl: 'mitral-valve-prolapse',
        info: 'Condition where the mitral valve, a heart valve that connects the left atrium to the left ventricle, fails to close properly.',
        intro: 'Mitral valve prolapse is a condition where the mitral valve, one of the valves in the heart that connects the left atrium to the left ventricle, does not close properly. Normally, the mitral valve allows blood to flow from the left atrium to the left ventricle. However, during prolapse, blood can flow back into the left atrium from the left ventricle. This is also known as mitral regurgitation.',
        causes: 'Mitral valve prolapse can arise without warning, or it can result from other medical conditions.',
        symptoms: 'There are no noticeable symptoms of mitral valve prolapse, and can only be detected during an exam by hearing a click or a heart murmur (a “woosh” or “swish” sound in a heartbeat) upon listening to the heart with a stethoscope. Chest pain, fast heart rate, fainting, or dizziness may be experienced by those suffering from mitral valve prolapse, but may not be caused by the disease. Mitral valve prolapse can result in some severe issues. Mitral regurgitation can induce breathing difficulty and fatigue. Infective endocarditis involves an infected heart valve, and can result in fever, chills, and fatigue. Fast or hard heartbeats can arise from mitral valve prolapse, and rarely the heart may stop beating.',
        diagnosis: 'Echocardiograms are the most effective diagnostic method for detecting mitral valve prolapse. They generate images of the heart through sound waves, displaying its chambers and its performance.',
        treatment: 'Mitral valve prolapse treatment depends on the severity of the condition, and if it is causing major problems. Mild mitral valve prolapse and mild mitral regurgitation should be monitored, but does not need to be treated immediately. If it worsens, or if you are diagnosed with severe mitral valve prolapse, then surgery to fix or replace the valve may be required. If you cannot get the surgery, then the doctor may reduce the leak by using a catheter to fix the valve. Other treatments include medications for high blood pressure, irregular heart rhythm, or blood clots (may be used for patients suffering from atrial fibrillation).'
    },
    {
        name: 'Carotid Artery Disease',
        offName: 'CAROTID ARTERY DISEASE',
        conditionUrl: 'carotid-artery-disease',
        info: 'Disease that renders patients more susceptible to strokes due to blockages arising in arteries connecting to the brain.',
        intro: 'Carotid artery disease causes patients to become more susceptible to strokes. The carotid arteries are vessels that carry blood to the brain. When those arteries are blocked, major damage to the brain can occur.',
        causes: 'Carotid artery disease refers to the buildup of plaque inside the carotid arteries, causing them to narrow. This limits blood flow to the brain, resulting in an increased risk of suffering from a stroke.',
        symptoms: 'The major symptom of carotid artery disease is a stroke. Strokes occur when blood flow to the brain is limited or blocked, resulting in brain damage. The blood clots formed in the carotid arteries can move to the brain and block smaller arteries. There are lasting effects from a stroke such as an inability to speak or understand speech, paralysis on one side of the body, an inability to dress, eat, or care for oneself without assistance. Another symptom of carotid artery disease is transient ischemic attack (TIA). They serve as very brief strokes that do not cause brain damage, as plaque can block a brain vessel for a short time and then reopen. However, patients who suffer from TIAs should take serious action against carotid artery disease, as they can result in a major stroke.',
        diagnosis: 'Tests for carotid artery disease involve capturing photos of the arteries. The carotid duplex ultrasound generates pictures from soundwaves similar to an echocardiogram. Magnetic resonance angiography (MRA) uses magnets to view the arteries that have been indicated by an injected dye via IV. Computed tomography angiography (CTA) involves the analysis of vessels by using a CT scan, a unique X-ray device. A cerebral angiogram may be employed, but it is rarely used.',
        treatment: 'The ideal way to reduce risk for carotid artery disease is to make positive changes for your body. Quit smoking, start exercising to gain muscle and lose excess weight, and adopt a low fat and cholesterol diet by eating more fruits, vegetables, and low-fat dairy foods. It is also advised to take medications for lowering blood pressure, lowering cholesterol (statins), and preventing blood clots (aspirin). If you are at high risk, then carotid stenting may be suggested. The stent is inserted into the carotid artery to open it more. Surgery to remove the plaque, carotid endarterectomy, may be suggested if patients suffered from a TIA or stroke caused by plaque buildup.'
    },
    {
        name: 'Peripheral Artery Disease',
        offName: 'PERIPHERAL ARTERIAL DISEASE',
        conditionUrl: 'peripheral-arterial-disease',
        info: 'Disease that involves worsening leg pain with exercise or activity due to plaque buildup in the leg arteries.',
        intro: 'Peripheral arterial disease causes leg pain that becomes more severe with activity or exercise. The disease affects the arteries in the legs, where blood flow can be more difficult, which puts more strain on the body.',
        causes: 'Arteries are usually affected by plaque buildup, which can narrow or block the artery and impact blood flow. As a result, with PAD, legs do not receive enough blood, causing pain in that area. Patients who smoke, and/or have diabetes, high cholesterol, or high blood pressure are at higher risk for PAD.',
        symptoms: 'The major indication of PAD is leg pain, specifically in the back of the lower leg. Leg pain worsens while walking or exercising, and improves when relaxing. Sometimes, that pain can even extend to the thighs, feet, and buttocks. Patients suffering from PAD may experience trouble with walking up stairs as well as erection (men) and arousal (women) difficulties.',
        diagnosis: 'There are three tests that can be done for diagnosing PAD. The first is to monitor your blood pressure in your arm and lower leg before and after exercising, and check if there is a major difference. Another is to monitor blood pressure in areas such as the thigh. An ultrasound to view images of your blood vessels may be recommended.',
        treatment: 'The best way to treat PAD is to make positive changes to your lifestyle. Make an effort to quit smoking, as doing so will improve your well-being. If diagnosed with such conditions, manage your diabetes, high blood pressure, and high cholesterol. Walking every day will improve PAD and help your blood flow. The doctor may recommend certain medications to treat symptoms of PAD and improve blood flow. Surgery may be required if symptoms do not improve after making lifestyle changes or taking medication. An angioplasty may be employed to expand the artery. The doctor will implant a tube into the blocked artery, and inflate a balloon within it to open the blockage. A stent may also be used to keep the artery open. Bypass surgery may be recommended. The doctor will make vein grafts out of existing vessels, and reroute blood flow through the new graft, avoiding the blocked site of the artery.',
    },
    {
        name: 'Ischemic Cardiomyopathy',
        offName: 'ISCHEMIC CARIOMYOPATHY',
        conditionUrl: 'ischemic-cardiomyopathy',
        info: 'A form of cardiomyopathy related to coronary artery disease where a damaged left ventricle causes blood to be pumped at a lower rate.',
        intro: 'Ischemic cardiomyopathy is a form of dilated cardiomyopathy where an enlarged, dilated, and weakened left ventricle causes blood to be pumped at a lower rate. It is, in fact, caused by ischemia, where the heart receives lower amounts of blood as a result of coronary artery disease and myocardial infarction (heart attack).',
        causes: 'Ischemia is caused by coronary artery disease and myocardial infarction (heart attack), both of which weaken the heart and prompt plaque buildup. Smoking, diabetes, high cholesterol, and obesity are underlying factors in causing ischemic cardiomyopathy.',
        symptoms: 'Patients with ischemic cardiomyopathy may experience shortness of breath, feet and leg swelling, increase in weight, and angina (chest pain and/or chest pressure). Patients may suffer fatigue while exercising, dizziness, and fainting.',
        diagnosis: 'The doctor may use one of many tests to find ischemic cardiomyopathy. Blood tests analyze and search for any chemicals released by the heart when induced by stress cardiomyopathy. Chest X-rays display any fluid present in the lungs, as well as the appearance of the heart and its major blood vessels. An electrocardiogram (ECG) evaluates the electrical activity of the heart, and determines if its performance is hindered by any conditions. An echocardiogram generates images of the beating heart using ultrasound to see if it is beating correctly. Cardiac catheterization monitors heart performance by injecting a catheter into a blood vessel in the arm or leg which travels to the heart. The catheter allows the doctor to take measurements of the heart and blood vessels. A coronary angiography may be performed, which is when a dye is injected to identify any narrow or blocked arteries in an X-ray. A stress test can also help with diagnosing for heart failure by measuring heart performance with exercise, allowing doctors to determine if your heart is pumping enough blood to the rest of the body while exercising.',
        treatment: 'The most convenient treatments are to make changes to your dieting and exercise. Avoid fat and cholesterol heavy meals and focus on improving your wellness. Avoid alcohol and smoking as well, as they can be detrimental to your health. The doctor may prescribe medications to improve cardiac function and combat symptoms of coronary artery disease. An implantable cardioverter-defibrillator surgery can be performed to help the heart beat normally. Coronary artery bypass surgery may be conducted to allow blood to avoid sections of arteries that are narrowed or blocked by plaque.',
    },
    {
        name: 'Dilated Cardiomyopathy',
        offName: 'DILATED CARIOMYOPATHY',
        conditionUrl: 'dilated-cardiomyopathy',
        info: 'A condition involving a weakened heart muscle that fails to pump blood at a normal rate.',
        intro: 'Dilated cardiomyopathy involves a weakened heart muscle that does not pump blood well. Patients with dilated cardiomyopathy experience a larger space inside the left ventricle, making it potentially more difficult to pump blood. Those with dilated cardiomyopathy are at risk for developing heart failure, meaning that the heart still works, but it has difficulty with providing the body with the right amount of blood and oxygen with each pump.',
        causes: 'Dilated cardiomyopathy can arise without an underlying cause. It can be genetic, and maybe it can result from a viral illness.',
        symptoms: 'Patients with dilated cardiomyopathy can experience difficulty breathing during exercise or while lying down or asleep. They can also have trouble with exercising or any other physical activity, and suffer swollen feet, ankles, or legs.',
        diagnosis: 'Blood tests analyze and search for any chemicals released by the heart when induced by stress cardiomyopathy. Chest X-rays display any fluid present in the lungs, as well as the appearance of the heart and its major blood vessels. An electrocardiogram (ECG) evaluates the electrical activity of the heart, and determines if its performance is hindered by any conditions. An echocardiogram generates images of the beating heart using ultrasound to see if it is beating correctly. Cardiac catheterization monitors heart performance by injecting a catheter into a blood vessel in the arm or leg which travels to the heart. The catheter allows the doctor to take measurements of the heart and blood vessels. A coronary angiography may be performed, which is when a dye is injected to identify any narrow or blocked arteries in an X-ray.',
        treatment: 'Lifestyle changes are best heeded when treating dilated cardiomyopathy. Adopt a diet that avoids fats and cholesterol, refrain from smoking and drinking alcohol, and exercise to improve your wellness. In case dilated cardiomyopathy causes heart failure, medications to treat symptoms may be prescribed. Devices that stimulate heart pumping are also beneficial for treating heart failure. Surgeries that improve blood flow by expanding arteries with plaque or creating bypass arteries can treat heart failure.',
    },
    {
        name: 'Hypertrophic Cardiomyopathy',
        offName: 'HYPERTROPHIC CARDIOMYOPATHY',
        conditionUrl: 'hypertrophic-cardiomypathy',
        info: 'Condition where the heart muscle increases in size, making it more difficult to pump blood.',
        intro: 'Hypertrophic cardiomyopathy involves the heart muscle to increase in size, making it more difficult to pump blood. It can result in difficulty breathing, chest pain, and fainting. The disease can lead to heart failure, heart rhythm disorders, heart block (where electrical signals are blocked from moving through the heart), stroke, heart valve infection, or death.',
        causes: 'The cause of hypertrophic cardiomyopathy is entirely genetic, and does not result from any outside conditions. It can develop during not only adulthood, but teenage years and childhood as well.',
        symptoms: 'Patients with hypertrophic cardiomyopathy can experience trouble with breathing (that may occur during exercise, or worsens while lying down but improves when sitting or standing). Chest pain that worsens with activity may occur, as well as a fast heart rate. Patients may experience fainting or feel like fainting, as well as swelling in the feet, ankles, or legs.',
        diagnosis: 'An electrocardiogram can be used to test for hypertrophic cardiomyopathy by measuring the electrical activity in the heart, and determine if your heart is beating in a normal pattern and rhythm. An echocardiogram may be suggested to capture images of the beating heart, monitoring the thickness of its walls, as well as the size of the chambers. If one family member has hypertrophic cardiomyopathy, then it is suggested that other family members be tested for it as well.',
        treatment: 'Medicine to control the symptoms of hypertrophic cardiomyopathy such as chest pain or abnormal heart rhythm is preferred. An implantable cardioverter-defibrillator surgery can be performed to help the heart beat normally. Surgery to remove parts of the heart muscle may be performed in case medicines do not work to treat disease.',
    },
    {
        name: 'Stress Cardiomyopathy',
        offName: 'STRESS CARDIOMYOPATHY',
        conditionUrl: 'stress-cardiomypathy',
        info: 'A stress induced heart condition that triggers breathing problems, chest pain, or even fainting.',
        intro: 'Stress cardiomyopathy, as the name suggests, is a stress induced heart condition that triggers breathing problems, chest pain, or even fainting. Heart performance decreases making pumping more difficult, usually around the apex of the heart (the lower end). As a result, that area of the heart increases in size. Stress cardiomyopathy can cause heart failure (when the heart does not pump blood well), abnormal heart rhythm, and heart valve issues.',
        causes: 'Stress cardiomyopathy results from physical and emotional stresses, and is more commonly found in older women. Such stresses include sudden medical illnesses, the loss of loved ones, or arguments.',
        symptoms: 'The main indicators of stress cardiomyopathy are sudden chest pain, difficulty breathing, or fainting. These symptoms may be brought on by physical and emotional traumas mentioned above.',
        diagnosis: 'These symptoms may be similar to those of a heart attack, so the doctor will need to run some exams to understand your condition. Blood tests analyze and search for any chemicals released by the heart when induced by stress cardiomyopathy. Chest X-rays display any fluid present in the lungs, as well as the appearance of the heart and its major blood vessels. An electrocardiogram (ECG) evaluates the electrical activity of the heart, and determines if its performance is hindered by any conditions. An echocardiogram generates images of the beating heart using ultrasound to see if it is beating correctly. Cardiac catheterization monitors heart performance by injecting a catheter into a blood vessel in the arm or leg which travels to the heart. The catheter allows the doctor to take measurements of the heart and blood vessels. A coronary angiography may be performed, which is when a dye is injected to identify any narrow or blocked arteries in an X-ray.',
        treatment: 'Medication prescribed by your doctor should counter the symptoms caused by stress cardiomyopathy. The doctor will indicate whether these medications should be taken for a short or an extended time. Recovery from stress cardiomyopathy can be between one to four weeks, but can recur again if not managed well.',
    },
]