export default
[
    {
        name: 'Atrial Fibrillation',
        conditionUrl: 'atrial-fibrillation',
        offName: 'ATRIAL FIBRILLATION',
        info: 'The most common arrhythmia where patients experience abnormal heart rhythm due to poor or inconsistent electrical signals.',
        arrhythmia: 'Arrhythmias are heart rhythm problems that result from faulty electricul impulses that coordinate your heart beats. They may cause your heart to beat too fast, too slow, or irregularly.',
        about: 'The most common arrhythmia type, atrial fibrillation (A-fib) puts patients at risk of stroke and even death. Those diagnosed with atrial fibrillation experience abnormal heartbeats due to faulty electrical signals. The atrial chambers (the top two chambers of the heart), have a harder time pumping blood, and its activity is weakened, resulting in a formation of blood clots. These clots can form anywhere in the arteries, and can produce strokes, heart attacks, or other major diseases.',
        causes: 'Potential causes of atrial fibrillation include high blood pressure, heart attack, coronary artery disease, abnormal heart valves, congenital heart defects, stimulant exposure (such as alcohol, caffeine, tobacco), lung diseases, sleep apnea, stress from surgery, previous heart surgery, or metabolic imbalance.',
        symptoms: 'Patients with atrial fibrillation may experience a racing heart, skipping beats, or irregular heart beats. Chest tightness or pain may be present as well. Lightheadedness, dizziness, fatigue, or sensations of passing out may occur. Difficulty breathing as well as performing exercise are symptoms of atrial fibrillation as well.',
        diagnosis: 'The most common test for atrial fibrillation is the electrocardiogram (ECG). This test measures the electrical activity of the heart, and analyzes any abnormalities in rhythm and performance. The Holter Monitor can be used to record heart activity for 24 to 48 hours and analyzes heart rhythm. Event Monitors measure the heart activity for up to one month, and can be activated when you are experiencing heart rhythm issues. Echocardiograms provide doctors and patients a look into the shape of the heart with the help of ultrasound, and visualize potential heart disease or blood clots. Blood tests analyze chemicals of substances that may indicate atrial fibrillation. A stress test also measures electrical activity while pushing the heart with exercise. A chest X-ray produces images of the heart and lungs to observe any fluid buildup within those areas.',
        treatment: 'Atrial fibrillation may resolve on its own, but patients should treat it. Common preventative measures include controlling the heartbeat. Medications to control heart rate or prevent blood clot formation are recommended. Cardioversion can be used to stabilize heartbeat by applying a low electrical current to the heart. Pacemaker implantation is a resourceful treatment, as it sends electrical signals to the heart to control the heart beat. Ablation may be applied to treat atrial fibrillation by using heat or cold to deactivate or destroy the part of the heart responsible for conducting abnormal electrical signals.',
    },
    {
        name: 'Atrial Flutter',
        conditionUrl: 'atrial-flutter',
        offName: 'ATRIAL FLUTTER',
        info: 'Arrhythmia type where the heart beats faster than average.',
        arrhythmia: 'Arrhythmias are heart rhythm problems that result from faulty electricul impulses that coordinate your heart beats. They may cause your heart to beat too fast, too slow, or irregularly.',
        about: 'Atrial flutter involves the heart beating faster than normal, and puts patients at risk for problems such as stroke. The electrical signals that prompt heartbeat become abnormal, causing the atrial chambers to pump out less blood than required. The blood left behind forms clots, which can travel to the brain through the arteries, causing strokes.',
        causes: 'There is no definite cause of the abnormal electrical signals that prompt atrial flutter. It may be potentially caused by ischemia, cardiomyopathy, defective heart valves, stimulant exposure (alcohol, caffeine, drugs), or even open-heart surgery.',
        symptoms: 'Patients with atrial flutter may experience rapid and hard heartbeats, and a skipping heartbeat. Fatigue, light-headedness, and dizziness may be felt as well, especially during exercise. There may be difficulty breathing, and chest pain. Patients may also experience fainting, or feeling like fainting.',
        diagnosis: 'The ideal test for diagnosing atrial flutter is an electrocardiogram (ECG). The electrical activity of the heart is measured to understand its performance and analyze any issues with pumping blood. A chest X-ray can be conducted to screen for fluid buildup in the lungs and heart. An echocardiogram creates images of the heart as it beats to understand its structure. Stress tests use ECGs on patients while they are exercising to analyze electrical activity of the heart while it is put to work. A Holter or Event Monitor can track any abnormalities in heartbeat over 24 to 48 hours or up to 4 weeks respectively.',
        treatment: 'Asymptomatic atrial flutter may require no treatment, as the heartbeat can return to normal within days or weeks without any preventative measures. If you are symptomatic, then medications to control heart rate or prevent blood clot formation are recommended. Cardioversion can be used to stabilize heartbeat by applying a low electrical current to the heart. Pacemaker implantation is a resourceful treatment, as it sends electrical signals to the heart to control the heart beat. Ablation may be applied to treat atrial fibrillation by using heat or cold to deactivate or destroy the part of the heart responsible for conducting abnormal electrical signals.',

    },
    {
        name: 'Supraventricular Tachycardia',
        conditionUrl: 'supraventricular-tachycardia',
        offName: 'SUPRAVENTRICULAR TACHYCARDIA',
        info: 'Type of arrhythmia where the heartbeat suddenly speeds up due to a faulty electrical system.',
        arrhythmia: 'Arrhythmias are heart rhythm problems that result from faulty electricul impulses that coordinate your heart beats. They may cause your heart to beat too fast, too slow, or irregularly.',
        about: 'Supraventricular tachycardia (SVT) is a faster heartbeat than normal. When it sometimes starts and stops suddenly, it is known as Paroxysmal supraventricular tachycardia (PSVT), meaning “sudden” supraventricular tachycardia. This fast heartbeat can last from 10 to 15 minutes, and continue into rest.',
        causes: 'The main cause of supraventricular tachycardia originates from an issue with the electrical system of the heart, and affects the atria. SVT can usually be triggered by exercise or stress. It can even be brought on by heart failure, stimulant exposure (smoking, alcohol, caffeine, drugs), open-heart surgery, heart disease, or pregnancy.',
        symptoms: 'Patients with supraventricular tachycardia may be asymptomatic, they may experience palpitations (heartbeat changes that involve the heart beating too fast or hard, or skipping of beats). You may also experience light-headedness, dizziness, and fatigue. If you are diagnosed with coronary artery disease, then you may also have trouble breathing, and feel chest tightness.',
        diagnosis: 'The ideal test for diagnosing supraventricular tachycardia is an electrocardiogram (ECG). The electrical activity of the heart is measured to understand its performance and analyze any issues with pumping blood. Other tests include the Holter Monitor, which records the activity of the heart for only 24 to 48 hours, or the Event Monitor, which measures the heart activity for up to one month, and can be activated when you are experiencing heart rhythm issues.',
        treatment: 'The best way to counter supraventricular tachycardia is to address its cause. If you are experiencing a high heart rate, then medications to slow it down or control it are recommended. Cardioversion can be used to stabilize heartbeat by applying a low electrical current to the heart. Ablation may be applied to treat atrial fibrillation by using heat or cold to deactivate or destroy the part of the heart responsible for conducting abnormal electrical signals.',
    },
    {
        name: 'Ventricular Tachycardia',
        conditionUrl: 'ventricular-tachycardia',
        offName: 'VENTRICULAR TACHYCARDIA',
        info: 'Condition where the heart beats even faster, resulting in dizziness, fainting, or even death.',
        arrhythmia: 'Arrhythmias are heart rhythm problems that result from faulty electricul impulses that coordinate your heart beats. They may cause your heart to beat too fast, too slow, or irregularly.',
        about: 'Like supraventricular tachycardia, ventricular tachycardia (VT) causes the heart to beat faster, resulting in pumping less blood. Pumping less blood can result in dizziness, fainting, or even death.',
        causes: 'The ventricles (the two lower chambers) alter the electrical system of the heart by sending abnormal signals resulting in a sped up heartbeat. The cause of this increase in speed can be from heart disease, heart attack, heart failure, heart surgery, or myocarditis. Medicines that control heart rhythm, an increase or decrease of certain chemicals in your blood, or a lack of oxygen can also cause ventricular tachycardia.',
        symptoms: 'A long lasting fast heartbeat can be dangerous and indicate ventricular tachycardia. Palpitations (sensations when the heart is beating fast or hard, or seems to skip a beat) are symptoms of ventricular tachycardia. Dizziness, difficulty breathing, chest pain, or fainting can be indicators of ventricular tachycardia as well.',
        diagnosis: 'The ideal test for diagnosing ventricular tachycardia is an electrocardiogram (ECG). The electrical activity of the heart is measured to understand its performance and analyze any issues with pumping blood. Other tests include the Holter Monitor, which records the activity of the heart for only 24 to 48 hours.',
        treatment: 'Medications to control heart rate are recommended to treat ventricular tachycardia. Cardioversion can be used to stabilize heartbeat by applying a low electrical current to the heart. Ablation may be applied to treat atrial fibrillation by using heat or cold to deactivate or destroy the part of the heart responsible for conducting abnormal electrical signals. Pacemaker implantation is a resourceful treatment, as it sends electrical signals to the heart to control the heart beat. An implantable cardioverter defibrillator (ICD) can use mild electrical currents to stabilize heart rhythm. Surgery that creates scar tissue in the heart can actually block abnormal electrical signals.',
    },
    {
        name: 'Ventricular Fibrillation',
        conditionUrl: 'ventricular-fibrillation',
        offName: 'VENTRICULAR FIBRILLATION',
        info: 'A severe form of arhythmia where the ventricles fail to pump blood out of the heart. This results in cardiac arrest, which may prompt death within minutes.',
        arrhythmia: 'Arrhythmias are heart rhythm problems that result from faulty electricul impulses that coordinate your heart beats. They may cause your heart to beat too fast, too slow, or irregularly.',
        about: 'Ventricular fibrillation is a severe condition that can result in death if not treated within minutes. As an arrhythmia, ventricular fibrillation generates abnormal electrical signals that control the heartbeat, causing the ventricles (the bottom chambers of the heart) to pump no blood. This results in sudden cardiac arrest, and can cause death if not treated immediately.',
        causes: 'Myocardial infarction (heart attack) is the most common cause of ventricular fibrillation. The heart muscle dies when one of the arteries that connects to the heart is blocked by plaque, and ventricular fibrillation occurs within a few days of a heart attack. However, patients who suffer from lung problems, severe injuries, or illegal drug intake can experience ventricular fibrillation.',
        symptoms: 'Those with ventricular fibrillation will collapse and pass out, and lose their pulse or heartbeat.',
        diagnosis: 'An electrocardiogram needs to be done to diagnose for ventricular fibrillation. The electrical activity of the heart needs to be measured to determine if the heart is functioning properly. A chest X-ray may be employed to view the heart and lungs for any fluid buildup. Blood tests can help the doctor find any abnormal chemicals in the blood which may be indicators of ventricular fibrillation. An echocardiogram can provide images of your beating heart to determine if your ventricles are pumping blood correctly. If cardiac arrest was caused by a heart attack, then cardiac catheterization may be recommended. The doctor will insert a tube into a blood vessel at the arm or leg, and move it up to the heart. Then, the doctor will conduct tests or unblock clogged arteries.',
        treatment: 'The immediate treatment for ventricular fibrillation is defibrillation, which uses a defibrillator to send electrical shocks to the heart to regain its normal rhythm. CPR can also treat ventricular fibrillation to get the blood and oxygen moving through the body when the heart stops beating. Compressions (pressing hard and fast on the chest) squeeze the heart to move blood again. When a patient is suffering from cardiac arrest, hypothermia can be used to cool the body down below normal temperature for 1-2 days, reducing brain injury (which can cause death after cardiac arrest). After surviving an episode of ventricular fibrillation, long term treatments must be started to counter the abnormal heartbeat. Your doctor may prescribe medications that stabilize heart rhythm. You may need an implantable cardioverter-defibrillator (ICD) to be placed near your heart to treat abnormal heartbeats. The ICD will send normal electrical signals to the heart to treat ventricular fibrillation. Ablation may also be used to treat ventricular fibrillation. The doctor will use heat or cold to destroy an area of the heart that serves as the source of the abnormal electrical signals. Ablation is only done if you have suffered multiple episodes of ventricular fibrillation.',
    },
]